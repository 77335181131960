<template>
    <div class="flex-1">
        <div v-loading="$wait.is('loading.details')" class="card article-container" element-loading-spinner="el-custom-spinner">
            <header class="border-b border-solid border-gray-300 pb-4 mb-5">
                <h1 v-if="articleUuid">
                    {{ $t('help_desk.edit') }}
                </h1>
                <h1 v-else>
                    {{ $t('help_desk.add_new') }}
                </h1>
            </header>

            <div class="flex justify-between items-end pb-4">
                <el-form ref="formTitle" :model="article" :rules="rules" @submit.native.prevent>
                    <el-form-item :label="$t('help_desk.title')" prop="title" class="w-72 reset-margin">
                        <el-input v-model="article.title" />
                    </el-form-item>
                </el-form>

                <div class="flex">
                    <el-form :model="article" :rules="rules" label-position="left" label-width="80px" class="flex" @submit.native.prevent>
                        <el-form-item :label="$t('help_desk.published')" class="reset-margin">
                            <el-switch v-model="article.status" :active-value="1" :inactive-value="0" class="pr-8" />
                        </el-form-item>
                    </el-form>

                    <el-form ref="formTwo" :model="article" :rules="rules" class="flex" @submit.native.prevent>
                        <el-form-item class="reset-margin" prop="receiver_type">
                            <el-select v-model="article.receiver_type" class="w-40">
                                <el-option value="client" :label="$t('help_desk.clients')" />
                                <el-option value="employee" :label="$t('help_desk.employees')" />
                                <el-option value="manager" :label="$t('help_desk.managers')" />
                            </el-select>
                        </el-form-item>

                        <el-form-item class="reset-margin" prop="language">
                            <el-select v-model="article.language" class="w-20 ml-4">
                                <el-option value="se" label="SE" />
                                <el-option value="en" label="EN" />
                                <el-option value="pl" label="PL" />
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <quill-editor
                ref="myQuillEditor"
                v-model="article.content"
                :options="editorOption"
            />
        </div>
        <div class="flex justify-end">
            <el-button v-if="articleUuid" type="danger" :loading="$wait.is('loading.details')" :disabled="!userCan('delete help desk')" @click="remove">
                {{ $t('common.delete') }}
            </el-button>
            <el-button v-if="!articleUuid" type="success" :loading="$wait.is('loading.details')" :disabled="!userCan('create help desk')" @click="create">
                {{ $t('common.add') }}
            </el-button>
            <el-button v-else type="primary" :loading="$wait.is('loading.details')" :disabled="!userCan('update help desk')" @click="update">
                {{ $t('common.update') }}
            </el-button>
        </div>
    </div>
</template>
<script>
// @Filip
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import queryString from 'query-string';
import { quillEditor } from 'vue-quill-editor';

export default {
    components: {
        quillEditor,
    },

    props: {
        articleUuidProp: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            articleUuid:  { ...this.articleUuidProp },
            article:      {},
            editorOption: {
                placeholder: ' ',
                debug:       false,
                modules:     {
                    toolbar: [
                        [{ header: 1 }, { header: 2 }],
                        ['bold', 'italic', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ align: [] }],
                    ],
                },
            },
            rules: {
                title:         [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                receiver_type: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                language:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    watch: {
        articleUuidProp: {
            handler() {
                this.articleUuid = this.articleUuidProp;
                this.article = {};
                this.getDetails();
            },
            immediate: true,
        },
    },

    methods: {
        async getDetails() {
            if (!this.articleUuid) return;
            this.$wait.start('loading.details');
            this.article = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/help_desk/${this.articleUuid}`).then(({ data }) => data);
            this.resetFields();
            this.$wait.end('loading.details');
        },

        async update() {
            this.$wait.start('loading.details');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/help_desk/${this.articleUuid}`, {
                title:         this.article.title,
                content:       this.article.content,
                language:      this.article.language,
                status:        this.article.status,
                receiver_type: this.article.receiver_type,
            });
            this.$emit('updated');
            this.$wait.end('loading.details');
            this.$notify.success({ title: this.$t('common.success') });
        },

        async create() {
            await new Promise(resolve => this.$refs.formTitle.validate(valid => valid && resolve()));
            await new Promise(resolve => this.$refs.formTwo.validate(valid => valid && resolve()));

            this.$wait.start('loading.details');
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/help_desk`, {
                title:         this.article.title,
                content:       this.article.content,
                language:      this.article.language,
                status:        this.article.status,
                receiver_type: this.article.receiver_type,
            });
            this.$emit('updated');
            this.$wait.end('loading.details');
            this.$notify.success({ title: this.$t('common.success') });
        },

        async remove() {
            this.$wait.start('loading.details');
            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/help_desk/${this.articleUuid}`);
            this.article = {};
            this.articleUuid = null;
            const query = queryString.parse(window.location.search, { sort: false });
            query.uuid = null;
            this.$router.replace({ query }).catch(() => {});
            this.$emit('updated');
            this.$wait.end('loading.details');
            this.$notify.success({ title: this.$t('common.success') });
        },

        resetFields() {
            this.$refs.formTitle.resetFields();
            this.$refs.formTwo.resetFields();
        },
    },
};
</script>
<style>
.article-container .ql-editor {
    height: calc(100vh - 355px);
}
.article-container .reset-margin {
    margin-bottom: 0 !important;
}
</style>
